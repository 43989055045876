import {useState} from 'react'
import styled from 'styled-components/native'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {RouteSignedOutParams} from '@ltb/libs/navigation/signed-out-navigator'
import {Button} from '@ltb/libs/components/button'
import {TextInput} from '@ltb/libs/components/text-input'
import {Iconography, fontS} from '@ltb/libs/design-system'
import {useCreateUserWithEmailPasswordMutation} from '@ltb/libs/graphql'
import {useAppWrapper} from '@ltb/libs/contexts/app-wrapper-context'
import {setItem} from '@ltb/libs/storage'
import {Header} from '@ltb/libs/components/header'

const SignUpWrapper = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

const InternalWrapper = styled.View`
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;
`

const IconWrapper = styled.View`
  padding: 50px;
`

const ErrorText = styled.Text`
  color: ${({theme}) => theme.error.background};
  ${fontS}
`

const Agreement = styled.Text`
  color: ${({theme}) => theme.secondary};
  ${fontS}
`

type SignUpProps = {
  storybookLoading?: boolean
  storybookError?: string
  storybookComplete?: boolean
}

export const SignUp = ({storybookLoading, storybookError, storybookComplete}: SignUpProps) => {
  const navigation = useNavigation<NavigationProp<RouteSignedOutParams>>()

  const {setLocalUser} = useAppWrapper()
  const [signUp, {data, error, loading}] = useCreateUserWithEmailPasswordMutation({
    onCompleted: async ({createUserWithEmailPassword}) => {
      if (!createUserWithEmailPassword?.token || !createUserWithEmailPassword?.user?.id) {
        console.log('Sign up failed')
        return
      }
      await setItem('ltb-token', createUserWithEmailPassword.token)
      setLocalUser(createUserWithEmailPassword.user)
    },
    onError: () => {
      console.warn('Sign up failed')
    },
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSignUp = async () => {
    await signUp({
      variables: {
        email,
        password,
      },
    })
  }

  const isComplete =
    storybookComplete ||
    (Boolean(data?.createUserWithEmailPassword.token) && Boolean(data?.createUserWithEmailPassword?.user?.id))
  const isErrored = storybookError || (error !== undefined && !data)
  const disabled = storybookLoading || loading || isComplete

  return (
    <SignUpWrapper>
      <Header leftIcon="ChevronLeft" leftOnPress={() => navigation.goBack()} title="Sign-in" />
      <InternalWrapper>
        <IconWrapper>
          <Iconography icon="LittleTinBox" size={70} />
        </IconWrapper>
        <TextInput
          label="Email"
          id="email"
          autoCapitalize="none"
          autoComplete="email"
          onChangeText={setEmail}
          disabled={disabled}
          variant={isErrored ? 'error' : undefined}
          testID="email-input"
        />
        <TextInput
          label="Password"
          id="password"
          textContentType="password"
          secureTextEntry
          autoComplete="current-password"
          onChangeText={setPassword}
          disabled={disabled}
          variant={isErrored ? 'error' : undefined}
          testID="password-input"
        />
        <ErrorText>{isErrored ? 'Create account failed' : ''}</ErrorText>
        <Button layout="responsive" type="info" size="medium" onPress={handleSignUp} disabled={disabled}>
          {isComplete ? 'Logged in' : disabled ? 'Logging in...' : 'Create account'}
        </Button>
        <Agreement>
          By creating an account you, and the person(s) named on your plans agree to our Terms of Service, and Privacy
          Policy.
        </Agreement>
      </InternalWrapper>
    </SignUpWrapper>
  )
}
