import {Platform} from 'react-native'
import Cookie, {CookieAttributes} from 'js-cookie'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Config from 'react-native-config'

type StorageKeys = 'ltb-token'

export const getItem = async (key: StorageKeys): Promise<string | undefined> => {
  let val: string | undefined

  try {
    if (Platform.OS === 'web') {
      val = await Cookie.get(key)
    }
    if (Platform.OS !== 'web') {
      val = (await AsyncStorage.getItem(key)) || undefined
    }
  } catch (error) {
    console.error('getItem', error)
  }
  return val
}

export const setItem = async (key: StorageKeys, value: string, cookieOptions?: CookieAttributes): Promise<void> => {
  const defaultCookieSettings: CookieAttributes = {
    expires: 30,
    sameSite: 'strict',
    secure: Config.LTB_ENV === 'local' ? false : true,
  }

  const cookieSettings = {
    ...defaultCookieSettings,
    ...cookieOptions,
  }

  try {
    if (Platform.OS === 'web') {
      await Cookie.set(key, value, cookieSettings)
    }
    if (Platform.OS !== 'web') {
      await AsyncStorage.setItem(key, value)
    }
  } catch (error) {
    console.error('setItem', error)
  }
  return
}

export const removeItem = async (key: StorageKeys): Promise<void> => {
  try {
    if (Platform.OS === 'web') {
      await Cookie.remove(key)
    }
    if (Platform.OS !== 'web') {
      await AsyncStorage.removeItem(key)
    }
  } catch (error) {
    console.error('removeItem', error)
  }

  return
}
