import {UserFragment, useCurrentUserLazyQuery} from '@ltb/libs/graphql'
import React, {createContext, useState, useContext, PropsWithChildren} from 'react'

type UserProviderProps = {
  user: UserFragment
}

type UserContextType = {
  user: UserFragment
  setUser: React.Dispatch<React.SetStateAction<UserFragment>>
  refetchUser: () => void
}

/* istanbul ignore next */
export const UserContext = createContext<UserContextType>({
  user: {} as UserFragment,
  setUser: () => {},
  refetchUser: () => {},
})

export const UserProvider = ({user: initialUser, children}: PropsWithChildren<UserProviderProps>) => {
  const [user, setUser] = useState<UserFragment>(initialUser)

  const [refetchUser] = useCurrentUserLazyQuery({
    onCompleted: data => {
      setUser(data.currentUser)
    },
  })

  return <UserContext.Provider value={{user, setUser, refetchUser}}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
