import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>}
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never}
export type Incremental<T> = T | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
}

export type CreateUserEmailPasswordInput = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type LoginEmailPasswordInput = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type LoginResponse = {
  __typename?: 'LoginResponse'
  token: Scalars['String']['output']
  user: User
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Creates a plan for the requesting user. */
  createPlan: Plan
  /** Creates a user with the given email and password. */
  createUserWithEmailPassword: LoginResponse
  /** Login a user by email and password. */
  loginEmailPassword: LoginResponse
}

export type MutationCreateUserWithEmailPasswordArgs = {
  input: CreateUserEmailPasswordInput
}

export type MutationLoginEmailPasswordArgs = {
  input: LoginEmailPasswordInput
}

export type Plan = {
  __typename?: 'Plan'
  id: Scalars['ID']['output']
  insertedAt: Scalars['String']['output']
  updatedAt: Scalars['String']['output']
}

export type Query = {
  __typename?: 'Query'
  /** Returns the user model for the requesting user. */
  currentUser: User
  /** Returns a OK message. This is a sample query that returns a string. */
  health: Scalars['String']['output']
}

export type User = {
  __typename?: 'User'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  insertedAt: Scalars['String']['output']
  plans: Array<UserPlan>
  updatedAt: Scalars['String']['output']
}

export type UserPlan = {
  __typename?: 'UserPlan'
  id: Scalars['ID']['output']
  insertedAt: Scalars['String']['output']
  plan: Plan
  relation: UserRole
  updatedAt: Scalars['String']['output']
  user: User
}

/** The role a user plays in an account */
export enum UserRole {
  NEXT_OF_KIN = 'NEXT_OF_KIN',
  OWNER = 'OWNER',
}

export type PlanFragment = {__typename?: 'Plan'; id: string; insertedAt: string; updatedAt: string}

export type UserFragment = {
  __typename?: 'User'
  id: string
  email: string
  insertedAt: string
  updatedAt: string
  plans: Array<{
    __typename?: 'UserPlan'
    id: string
    insertedAt: string
    updatedAt: string
    relation: UserRole
    user: {__typename?: 'User'; id: string; email: string; insertedAt: string; updatedAt: string}
    plan: {__typename?: 'Plan'; id: string; insertedAt: string; updatedAt: string}
  }>
}

export type CreatePlanMutationVariables = Exact<{[key: string]: never}>

export type CreatePlanMutation = {
  __typename?: 'Mutation'
  createPlan: {__typename?: 'Plan'; id: string; insertedAt: string; updatedAt: string}
}

export type CreateUserWithEmailPasswordMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type CreateUserWithEmailPasswordMutation = {
  __typename?: 'Mutation'
  createUserWithEmailPassword: {
    __typename?: 'LoginResponse'
    token: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      insertedAt: string
      updatedAt: string
      plans: Array<{
        __typename?: 'UserPlan'
        id: string
        insertedAt: string
        updatedAt: string
        relation: UserRole
        user: {__typename?: 'User'; id: string; email: string; insertedAt: string; updatedAt: string}
        plan: {__typename?: 'Plan'; id: string; insertedAt: string; updatedAt: string}
      }>
    }
  }
}

export type LoginEmailPasswordMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginEmailPasswordMutation = {
  __typename?: 'Mutation'
  loginEmailPassword: {
    __typename?: 'LoginResponse'
    token: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      insertedAt: string
      updatedAt: string
      plans: Array<{
        __typename?: 'UserPlan'
        id: string
        insertedAt: string
        updatedAt: string
        relation: UserRole
        user: {__typename?: 'User'; id: string; email: string; insertedAt: string; updatedAt: string}
        plan: {__typename?: 'Plan'; id: string; insertedAt: string; updatedAt: string}
      }>
    }
  }
}

export type CurrentUserQueryVariables = Exact<{[key: string]: never}>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser: {
    __typename?: 'User'
    id: string
    email: string
    insertedAt: string
    updatedAt: string
    plans: Array<{
      __typename?: 'UserPlan'
      id: string
      insertedAt: string
      updatedAt: string
      relation: UserRole
      user: {__typename?: 'User'; id: string; email: string; insertedAt: string; updatedAt: string}
      plan: {__typename?: 'Plan'; id: string; insertedAt: string; updatedAt: string}
    }>
  }
}

export const PlanFragmentDoc = gql`
  fragment Plan on Plan {
    id
    insertedAt
    updatedAt
  }
`
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    insertedAt
    updatedAt
    plans {
      id
      insertedAt
      updatedAt
      relation
      user {
        id
        email
        insertedAt
        updatedAt
      }
      plan {
        ...Plan
      }
    }
  }
  ${PlanFragmentDoc}
`
export const CreatePlanDocument = gql`
  mutation CreatePlan {
    createPlan {
      ...Plan
    }
  }
  ${PlanFragmentDoc}
`
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>,
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options)
}
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>
export const CreateUserWithEmailPasswordDocument = gql`
  mutation CreateUserWithEmailPassword($email: String!, $password: String!) {
    createUserWithEmailPassword(input: {email: $email, password: $password}) {
      token
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type CreateUserWithEmailPasswordMutationFn = Apollo.MutationFunction<
  CreateUserWithEmailPasswordMutation,
  CreateUserWithEmailPasswordMutationVariables
>

/**
 * __useCreateUserWithEmailPasswordMutation__
 *
 * To run a mutation, you first call `useCreateUserWithEmailPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithEmailPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithEmailPasswordMutation, { data, loading, error }] = useCreateUserWithEmailPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserWithEmailPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserWithEmailPasswordMutation,
    CreateUserWithEmailPasswordMutationVariables
  >,
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateUserWithEmailPasswordMutation, CreateUserWithEmailPasswordMutationVariables>(
    CreateUserWithEmailPasswordDocument,
    options,
  )
}
export type CreateUserWithEmailPasswordMutationHookResult = ReturnType<typeof useCreateUserWithEmailPasswordMutation>
export type CreateUserWithEmailPasswordMutationResult = Apollo.MutationResult<CreateUserWithEmailPasswordMutation>
export type CreateUserWithEmailPasswordMutationOptions = Apollo.BaseMutationOptions<
  CreateUserWithEmailPasswordMutation,
  CreateUserWithEmailPasswordMutationVariables
>
export const LoginEmailPasswordDocument = gql`
  mutation LoginEmailPassword($email: String!, $password: String!) {
    loginEmailPassword(input: {email: $email, password: $password}) {
      token
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type LoginEmailPasswordMutationFn = Apollo.MutationFunction<
  LoginEmailPasswordMutation,
  LoginEmailPasswordMutationVariables
>

/**
 * __useLoginEmailPasswordMutation__
 *
 * To run a mutation, you first call `useLoginEmailPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginEmailPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginEmailPasswordMutation, { data, loading, error }] = useLoginEmailPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginEmailPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginEmailPasswordMutation, LoginEmailPasswordMutationVariables>,
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<LoginEmailPasswordMutation, LoginEmailPasswordMutationVariables>(
    LoginEmailPasswordDocument,
    options,
  )
}
export type LoginEmailPasswordMutationHookResult = ReturnType<typeof useLoginEmailPasswordMutation>
export type LoginEmailPasswordMutationResult = Apollo.MutationResult<LoginEmailPasswordMutation>
export type LoginEmailPasswordMutationOptions = Apollo.BaseMutationOptions<
  LoginEmailPasswordMutation,
  LoginEmailPasswordMutationVariables
>
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      ...User
    }
  }
  ${UserFragmentDoc}
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>
