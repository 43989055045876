import {useUser} from '@ltb/libs/contexts/user-context'
import {Button} from '@ltb/libs/components/button'
import {useCreatePlanMutation} from '@ltb/libs/graphql'
import {Header} from '@ltb/libs/components/header'
import {
  ContentText,
  HeadingText,
  InternalContent,
  InternalView,
  LandingWrapper,
  StyledText,
} from './signed-in-landing.styles'
import {useNavigation, NavigationProp} from '@react-navigation/native'
import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {ROUTE_DRAWER_MODAL} from '@ltb/libs/navigation/constants'

export const SignedInLanding = () => {
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()
  const {user, refetchUser} = useUser()

  const [createPlan] = useCreatePlanMutation({
    onCompleted: () => refetchUser(),
  })

  if (user.plans.length === 0) {
    return (
      <LandingWrapper>
        <Header leftIcon="LittleTinBox" rightIcon="Menu" rightOnPress={() => navigation.navigate(ROUTE_DRAWER_MODAL)} />
        <InternalView>
          <InternalContent>
            <HeadingText>You haven't created any plans</HeadingText>
            <ContentText>
              Our plans come with an array of tools to safeguard you and your family's information.
            </ContentText>
            <Button layout="fixed" size="medium" type="info" onPress={() => createPlan()}>
              Create plan
            </Button>
          </InternalContent>
        </InternalView>
      </LandingWrapper>
    )
  }

  return (
    <LandingWrapper>
      <Header leftIcon="LittleTinBox" rightIcon="Menu" />

      <StyledText>{user.email}</StyledText>
      {user.plans.map(plan => (
        <StyledText key={plan.id}>
          {plan.plan.id} - {plan.relation}
        </StyledText>
      ))}
    </LandingWrapper>
  )
}
