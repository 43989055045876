import {createNativeStackNavigator, NativeStackScreenProps} from '@react-navigation/native-stack'
import {useTheme} from 'styled-components/native'
import {ROUTE_DRAWER_MODAL, ROUTE_SIGNED_IN_LANDING, ROUTE_SIGNED_IN_STACK} from './constants'
import {RouteRootParams} from './root-navigator'
import {UserProvider} from '../contexts/user-context'
import {Drawer} from '../screens/drawer'
import {SignedInLanding} from '../screens/signed-in-landing'

export type RouteSignedInParams = {
  [ROUTE_SIGNED_IN_LANDING]: undefined
  [ROUTE_DRAWER_MODAL]: undefined
}

const Stack = createNativeStackNavigator<RouteSignedInParams>()

export const SignedInNavigator = (props: NativeStackScreenProps<RouteRootParams, typeof ROUTE_SIGNED_IN_STACK>) => {
  const theme = useTheme()
  const background = {backgroundColor: theme.background}
  return (
    <UserProvider user={props.route.params.user}>
      <Stack.Navigator
        initialRouteName={ROUTE_SIGNED_IN_LANDING}
        screenOptions={{
          headerShown: false,
          gestureEnabled: false,
          contentStyle: background,
        }}>
        <Stack.Screen name={ROUTE_SIGNED_IN_LANDING} component={SignedInLanding} />
        <Stack.Screen
          name={ROUTE_DRAWER_MODAL}
          component={Drawer}
          options={{
            animation: 'fade',
            presentation: 'transparentModal',
            headerShown: false,
            contentStyle: {
              backgroundColor: 'transparent',
            },
          }}
        />
      </Stack.Navigator>
    </UserProvider>
  )
}
