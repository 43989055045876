import {SideModal} from '@ltb/libs/layouts/side-modal'
import {useNavigation, NavigationProp} from '@react-navigation/native'
import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {InternalWrapper, MenuInternalWrapper, MenuItemWrapper, MenuText} from './drawer.styles'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {HorizontalDivision} from '@ltb/libs/components/division'
import {Pressable} from '@ltb/libs/components/pressable'

export const Drawer = ({storybookWidth}: {storybookWidth?: number}) => {
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()
  const insets = useSafeAreaInsets()

  return (
    <SideModal onClose={() => navigation.goBack()} originDirection="left" storybookWidth={storybookWidth}>
      <InternalWrapper insets={insets}>
        <Pressable onPress={() => navigation.goBack()}>
          <MenuItemWrapper insets={insets}>
            <MenuInternalWrapper>
              <MenuText>Close</MenuText>
            </MenuInternalWrapper>
          </MenuItemWrapper>
        </Pressable>
        <HorizontalDivision />
        <Pressable onPress={() => alert('logout')}>
          <MenuItemWrapper insets={insets}>
            <MenuInternalWrapper>
              <MenuText>Logout</MenuText>
            </MenuInternalWrapper>
          </MenuItemWrapper>
        </Pressable>
        <HorizontalDivision />
      </InternalWrapper>
    </SideModal>
  )
}
