import {fontLContrast, fontM} from '@ltb/libs/design-system'
import styled from 'styled-components/native'

export const LandingWrapper = styled.View`
  flex-grow: 1;
`

export const StyledText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontLContrast}
`

export const InternalView = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

export const InternalContent = styled.View`
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
`

export const HeadingText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontLContrast}
  text-align: center;
`

export const ContentText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontM}
  text-align: center;
`
