// Root level screems
export const ROUTE_SPLASH = 'Loading'
export const ROUTE_SIGNED_OUT_STACK = 'RouteSignedOutStack'
export const ROUTE_SIGNED_IN_STACK = 'RouteSignedInStack'
export const ROUTE_NOT_FOUND = '404'

// Signed out screens
export const ROUTE_SIGNED_OUT_LANDING = 'Welcome'
export const ROUTE_LOGIN = 'Log-in'
export const ROUTE_SIGN_UP = 'Sign-in'

// Signed in screens
export const ROUTE_DRAWER_MODAL = 'Menu'
export const ROUTE_SIGNED_IN_LANDING = 'Home'
