import fetch from 'cross-fetch'
import {setContext} from '@apollo/client/link/context'
import {RetryLink} from '@apollo/client/link/retry'
import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from '@apollo/client'
import Config from 'react-native-config'
import {getItem} from '@ltb/libs/storage'

const getLink = (): ApolloLink => {
  const httpLink = createHttpLink({
    uri: `${Config.API_URL}/dev/graphql`,
    fetch,
  })

  const authLink = setContext(async (_, {headers}) => {
    const newHeaders = headers || {}
    const token = await getItem('ltb-token')
    if (token) {
      newHeaders.Authorization = `Bearer ${token}`
    }
    return {
      headers: newHeaders,
    }
  })

  const retryLink = new RetryLink({
    delay: {
      jitter: true,
      max: Infinity,
      initial: 50,
    },
    attempts: {
      retryIf: (error, operation) => {
        const isError = Boolean(error)
        if (isError) console.log('error', error, operation.getContext().clientName)
        return isError
      },
      max: 10,
    },
  })

  const directionalLink = retryLink.concat(authLink.concat(httpLink))

  return directionalLink
}

export const apolloClient = new ApolloClient({
  link: getLink(),
  name: `ltb-mobile-${Config.APP_VERSION}`,
  cache: new InMemoryCache(),
})
