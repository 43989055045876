import {UserFragment} from '@ltb/libs/graphql'
import React, {createContext, useContext, PropsWithChildren} from 'react'

type AppWrapperProviderProps = {
  setLocalUser: React.Dispatch<React.SetStateAction<UserFragment | undefined>>
}

type AppWrapperContextType = {
  setLocalUser: React.Dispatch<React.SetStateAction<UserFragment | undefined>>
}

/* istanbul ignore next */
const AppWrapperContext = createContext<AppWrapperContextType>({
  setLocalUser: () => {},
})

export const AppWrapperProvider = ({setLocalUser, children}: PropsWithChildren<AppWrapperProviderProps>) => {
  return <AppWrapperContext.Provider value={{setLocalUser}}>{children}</AppWrapperContext.Provider>
}

export const useAppWrapper = () => useContext(AppWrapperContext)
